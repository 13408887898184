import { Http, ITrackingContext } from "@elixir/fx";
import {Status, IService, IEvent, IExceptionRequest, DeploymentRiskEstimation, ExceptionRequestBody, IdNamePair, NameEmailPair, ApprovalProvider} from "../Types";
import EnvironmentResolver from "./EnvironmentResolver";
import { AxiosPromise } from 'axios';

export abstract class ApiClient {
    protected apiHost: string | undefined = `${new EnvironmentResolver().apiHost}`;
    protected host: string | undefined = `${new EnvironmentResolver().apiHost}/${process.env.REACT_APP_SERVICESINFO_ENDPOINT}`;

    private getContext(): ITrackingContext {
        return {
            trackingId: new Date().toJSON(),
        };
    }

    /**
     * get the data to a REST/OData endpoint for the requested URL
     *
     * @param url The URL to get the data from
     */
    protected async get<T>(url: string): Promise<T> {
        const response = await Http.get<T>(this.getContext(), `${this.host}/${url}`);
        return response.data;
    }

    /**
     * post the data to a REST/OData endpoint for the requested URL
     *
     * @param url The URL to get the data from
     */
    protected async post<T, P>(url: string, data?: P): Promise<T> {
        const response = await Http.post<T>(this.getContext(), `${this.host}/${url}`, data);
        return response.data;
    }

    /**
     * post the data to a REST/OData endpoint for the requested URL
     * returns an axios promise instead of a promise
     *
     * @param url The URL to get the data from
     */
     protected postwithaxiosresponse<T, P>(url: string, data?: P): AxiosPromise<T> {
        return Http.post<T>(this.getContext(), `${this.host}/${url}`, data);
    }

    /**
     * Put the data to a REST/OData endpoint for the requested URL
     *
     * @param url The URL to get the data from
     */
    protected async put<T, P>(url: string, data: P): Promise<T> {
        const response = await Http.put<T>(this.getContext(), `${this.host}/${url}`, data);
        return response.data;
    }

    /**
     * Delete the data based on an Id to a REST/OData endpoint for the requested URL
     *
     * @param url The URL to get the data from
     */
    protected async delete<T>(url: string, data?: T): Promise<void> {
        const response = await Http.delete(this.getContext(), `${this.host}/${url}`, data);
        return response.data;
    }
}

export class ServicesInformationClient extends ApiClient {
    protected host: string = `${this.apiHost}/${process.env.REACT_APP_SERVICESINFO_ENDPOINT}`;

    public getServicesByName(serviceNameQuery: string): Promise<IdNamePair[]> {
        const serviceNameParam = `serviceNames=${serviceNameQuery}&maxresults=10`;
        return this.get(`service/names/?${serviceNameParam}`);
    }

    public getServicesByIds(ids: string[]): Promise<IdNamePair[]> {
        const serviceIdParams = `ServiceIds=${ids.join("&ServiceIds=")}`;
        return this.get(`service/ids/?${serviceIdParams}`);
    }

    public getService(guid: string): Promise<IService> {
        return this.get(`service/${guid}`);
    }

    public getServiceApprovers(serviceId: string): Promise<NameEmailPair[]> {
        return this.get(`approvers/${serviceId}`);
    }
}

export class ExceptionRequestClient extends ApiClient {
    protected host: string = `${this.apiHost}/${process.env.REACT_APP_EXCEPTIONREQUEST_ENDPOINT}`;

    getAllExceptionRequests(): Promise<IExceptionRequest[]> {
        const allStatusParam = `?statuses=${Object.values(Status).join("&statuses=")}`;
        return this.get(allStatusParam);
    }

    public getExceptionRequest(exceptionRequestId: string): Promise<IExceptionRequest> {
        return this.get(`${exceptionRequestId}`);
    }

    public getExceptionRequestsByServiceIds(serviceIds: string[]): Promise<IExceptionRequest[]> {
        const serviceIdParams = `ServiceIds=${serviceIds.join("&ServiceIds=")}`;
        return this.get(`?${serviceIdParams}`);
    }

    public updateExceptionRequest(exceptionRequestId: string, exceptionRequest: IExceptionRequest): Promise<IExceptionRequest> {
        return this.put<IExceptionRequest, IExceptionRequest>(`${exceptionRequestId}`, exceptionRequest);
    }

    public createNewExceptionRequest(exceptionRequestBody: ExceptionRequestBody): AxiosPromise<IExceptionRequest> {
        return this.postwithaxiosresponse<IExceptionRequest, ExceptionRequestBody>("preapproval", exceptionRequestBody);
    }

    public createNewExceptionRequestFromDeployment(deploymentId: string): Promise<IExceptionRequest> {
        return this.post<IExceptionRequest, {}>(`fromdeployment/?deploymentUrlId=${deploymentId}`);
    }
}

export class EventsRetrievalClient extends ApiClient {
    protected host: string = `${this.apiHost}/${process.env.REACT_APP_EVENTSRETRIEVAL_ENDPOINT}`;

    public getEvent(eventId: string): Promise<IEvent> {
        return this.get(`events/${eventId}/light`);
    }

    public getEvents(startDate: string, endDate: string): Promise<IEvent[]> {
        const startDateParam = `startDate=${startDate}`;
        const endDateParam = `&endDate=${endDate}`;
        return this.get(`events/light?${startDateParam}${endDateParam}`);
    }
}

export class ChangeAssessmentClient extends ApiClient {
    protected host: string = `${this.apiHost}/${process.env.REACT_APP_CHANGEASSESSMENT_ENDPOINT}`;

    public getDeploymentAssessment(deploymentChangeAssessmentId: string): Promise<DeploymentRiskEstimation> {
        return this.get(`ev2/?deploymentUrl=${deploymentChangeAssessmentId}`);
    }
    
    public getApprovalProviders(): Promise<ApprovalProvider[]> {
        return this.get('v2/approvalProviders');
    }
}
